<template>
  <div style="padding: 25px" class="max-w-1000px m-auto">
    <img src="/media/custom/orbi-bds-horizontal.png" alt="ORBI" class="orbi-logo mb-5">
    <multiselect-form-group
      class="app-view__form-group mb-5 w-200px"
      :placeholder="$t('placeholder.choose_language')"
      :value="langOptions.find(el => el.id === lang)"
      :options="langOptions"
      @select="changeLanguage($event.id)"
    />
    <div class="terms-wrapper px-10 py-5">
      <div v-if="lang === 'ge'">
        <p class="font-size-h6 font-weight-boldest mb-5">წესები და პირობები</p>
        <p>მიმდინარე შეთანხმების პირობების შესაბამისად - მომხმარებელი თანახმაა კომპანია ორბი ჯგუფს დასამუშავებლად</p>
        <p>გადასცეს პერსონალური მონაცემები, რომელიც უნდა მიუთითოს პირადი კაბინეტის რეგისტრაციის დროს.</p>
        <p>
          პირადი კაბინეტის რეგისტრაცია ითვლება დასრულებულად, მას შემდეგ რაც მომხმარებელი დაეთანხმება მიმდინარე
          სალიცენზიო შეთანხმებას.
        </p>
        <p>
          მომხმარებელი მეილზე მიიღებს შეტყობინებას, რომელიც შეიცავს ავტორიზაციისთვის საჭირო უნიკალურ სახელს
          და პაროლს,რომელიც გენერიებულია, მხოლოდ კონკრეტული მომხმარებლისთვის.
        </p>
        <p>
          პირადი კაბინეტის მომხმარებელი მხოლოდ თვითონ არის პასუხიმგებელი, ავტორიზაციისთვის საჭირო,
          მომხმარებლის სახელის და პაროლის უსაფრთხოდ შენახვასა და მესამე პირზე გადაცემაზე.
        </p>
        <p>
          ნებისმიერი მოქმედება, რომელიც შესრულებულია მომხმარებლის მიერ, პირადი კაბინეტის საშუალებით, ჩაითვლება
          მომხმარებლის პირადი ნების გამოხატვად.
        </p>
        <p>
          მომხმარებელი აცნობიერებს, იმას რომ, მესამე პირის დაშვება პირად კაბინეტთან გულისხმობს, მესამე პირის დაშვებას
          პირად მონაცემებთან და ამ მოქმედებაზე მომხმარებელი პირადად (დამოუკიდებლად) არის პასუხისმგებელი.
        </p>
        <p>
          კომპანია ორბი ჯგუფი უფლებას იტოვებს მოახდინოს პირადი კაბინეტის ფუნქციონალის განახლებება ან ცვლილება,
          რათა გაზარდოს პროდუქტის ფუნქციონალი და გააუმჯობესოს მისი შესაძლებლობები. ან გამორიცხოს შესაბამისი
          ფუნქციონალი. პირადი კაბინეტით სარგებლობისას მომხმარებელს შესაძლებლობა აქვს კომპანიისგან მიიღოს ტექნიკური
          მხარდაჭერა იმ საკითხებზე, რომელიც მას გაუჩნდება პირადი კაბინეთით სარგებლობისას.
        </p>
      </div>
      <div v-if="lang === 'en'">
        <p class="font-size-h6 font-weight-boldest mb-5">Terms and conditions</p>
        <p>
          In accordance with the terms of this Agreement, the User agree to the processing by Orbi Group of the User's
          personal data write in registration form of the Personal Account.
        </p>
        <p>
          Registration of the Personal Account is considered completed at the moment of acceptance of the terms of
          this License Agreement. The User receives an e-mail confirming the registration of the Personal Account with
          a unique Login and Password generated only for a specific User.
        </p>
        <p>
          The User of the Personal Account is fully responsible for the safety of his Login and password and their
          transfer to third persons.
        </p>
        <p>
          Any action performed through the User's Personal Account is considered an action and expression of the
          User's will.
        </p>
        <p>
          The User, by giving access to view personal data to other (third) persons, is aware that he provides access
          to all data contained in the Personal Account and independently bears full responsibility.
        </p>
        <p>
          Orbi Group reserves the right to modify or release updates to the Personal Account, add new features or
          functionality of the software that increase its performance or otherwise improve its characteristics, or
          exclude the corresponding functionality.
        </p>
        <p>
          When using the Personal Account, the User has the opportunity to receive technical support from the company
          on issues that arise in the process of using the functions of the Personal Account. Orbi Group reserves
          the right to modify or release updates to the Personal Account, add new features or functionality of
          the software that increase its performance or otherwise improve its characteristics, or exclude
          the corresponding functionality.
        </p>
      </div>
      <div v-if="lang === 'ru'">
        <p class="font-size-h6 font-weight-boldest mb-5">Правила и условия</p>
        <p>
          В соответствии с условиями настоящего Соглашения - Пользователь дает согласие на обработку компанией
          Орби Груп персональных данных Пользователя, указываемых при регистрации Личного Кабинета.
        </p>
        <p>
          Регистрация Личного Кабинета считается оконченной в момент согласия с условиями данного Лицензионного
          Соглашения. На электронную почту Пользователя приходит письмо, подтверждающее прохождение регистрации
          Личного Кабинета с уникальным Логином и Паролем сгенерированным только для конкретного Пользователя.
        </p>
        <p>
          Пользователь Личного Кабинета самостоятельно несет ответственность за сохранность своего Логина и
          пароля и передачу их третьим лицам.
        </p>
        <p>
          Любое действие, совершенное посредством Личного кабинета Пользователя, считается действием и выражением
          воли Пользователя.
        </p>
        <p>
          Пользователь, предоставляя доступ к просмотру персональных данных иным (третьим) лицам, осознает, что
          предоставляет доступ ко всем данным, содержащимся в Личном Кабинете и самостоятельно несет полную
          ответственность.
        </p>
        <p>
          Компания Орби Груп оставляет за собой право модифицировать или выпускать обновления Личного Кабинета,
          добавлять новые свойства или функциональные возможности программного обеспечения, повышающие его
          работоспособность или иным способом улучшающие его характеристики,
          либо исключать соответствующий функционал.
          При использовании Личного Кабинета – Пользователь имеет возможность получить техническую поддержку компании
          по вопросам, возникающим в процессе пользования функциями Личного Кабинета.
        </p>
      </div>
    </div>
    <div v-if="showActionButtons" class="d-flex bg-white border border-top-dark-50 p-3">
      <b-button class="px-15 w-50 mr-2" variant="outline-success" @click="activateTerms('yes')">
        <span class="far fa-handshake"></span>
        {{ $t('btn.yes') }}
      </b-button>
      <b-button class="px-15 w-50" variant="outline-danger" @click="activateTerms('yes')">
        <span class="far fa-ban"></span>
        {{ $t('btn.no') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import {TERMS_ACTIVATE} from '@/store/core/auth.module';
import notify from '@/core/services/notify.service';
import i18nService from '@/core/services/i18n.service';
import MultiselectFormGroup from '@/components/elements/form-groups/MultiselectFormGroup.vue';

export default {
  components: {
    MultiselectFormGroup
  },
  data: () => ({
    lang: 'en',
    languages: i18nService.languages,
    showActionButtons: false
  }),
  beforeMount() {
    this.showActionButtons = this.$route.query?.access_id && this.$route.query?.token;

    const queryLang = this.$route.query?.lang;
    if (queryLang && this.languages.some(language => language.lang === queryLang)) {
      this.changeLanguage(queryLang);
    }
  },
  computed: {
    langOptions() {
      return this.languages.map(item => {
        return {id: item.lang, label: this.$t(`lang.${item.lang}`)};
      });
    }
  },
  methods: {
    changeLanguage(lang) {
      this.lang = lang;
      this.$i18n.locale = lang;
    },
    activateTerms(value) {
      this.$store.dispatch(TERMS_ACTIVATE, {
        accessId: this.$route.query.access_id,
        token: this.$route.query.token,
        payload: {submit: value}
      })
        .then(response => {
          this.notifyAndRedirect('success', this.translateApiResponseMessage(response.data.message));
        })
        .catch(error => {
          this.notifyAndRedirect('error', this.translateApiResponseMessage(error.response?.data?.message));
        })
        .finally(() => {
          this.showActionButtons = false;
        });
    },
    translateApiResponseMessage(message) {
      let translate = message;
      switch (message) {
        case 'Token was not correct.':
          translate = this.$t('notify.incorrect_token');
          break;
        case 'Request was already handled.':
          translate = this.$t('notify.request_already_handled');
          break;
        case 'you canceled account activation.':
          translate = this.$t('notify.you_canceled_account_activation');
          break;
        case 'Your account has been activated. check your email.':
          translate = this.$t('notify.your_account_activated');
          break;
      }

      return translate;
    },
    notifyAndRedirect(type, message) {
      notify[type](message);
      setTimeout(() => {
        notify.hide();
        this.$router.push({name: 'login'});
      }, 2500);
    }
  }
}
</script>

<style lang="scss" scoped>
.orbi-logo {
  width: 25%;
}

.terms-wrapper {
  background: #FFF;
  border-radius: 4px;
}
</style>